import { zodResolver } from '@hookform/resolvers/zod'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Alert, Stack } from '@mui/material'
import { pipe } from 'ramda'
import React, { useEffect, FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import uriTemplate from 'uri-templates'

import {
  PageLayout,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
  Grid,
} from 'common/components-mui'
import { TextField, Checkbox } from 'common/components-mui/react-hook-form'
import { useParams, useRedirect, useScrollToTop } from 'common/hooks'
import { addOptionalPropIf, enqueueSnackbar } from 'common/utils'

import { getChancelleryData, saveChancellery } from '../actions'
import {
  ChancelleryLocationsTable,
  GlobalHolidays,
  OrderVolumeSetting,
  FallbackIssueWarnings,
  SimpleUserTable,
  MatchingConfigsTable,
} from '../components'
import { InputLegend } from '../components/InputLegend'
import { MatchingsTable } from '../components/MatchingsTable'
import { ChancelleryFormPageParams } from '../interfaces'
import { chancelleryInitialValues, chancellerySchema, ChancelleryFormValues, LocalOrderVolume } from '../interfaces/formSchemas'
import { ChancelleryInput, GetChancelleryQuery, SaveChancelleryMutation } from '../interfaces/schemaDefinition'
import { mapChancelleryAPIDataToForm, onError } from '../utils'

const TO_OVERVIEW = '/chancelleries'
const TO_EDIT_VIEW = uriTemplate('/chancelleries/edit/{id}')

export const ChancelleryFormPage: FC = () => {
  const { id } = useParams<ChancelleryFormPageParams>()
  const redirect = useRedirect()
  const [isLoading, setIsLoading] = useState(false)
  const [matchingConfigs, setMatchingConfigs] = useState<GetChancelleryQuery['chancellery']['matchingConfigs']>([])
  const [matchings, setMatchings] = useState<GetChancelleryQuery['chancellery']['matchings']>([])
  const methods = useForm({
    defaultValues: chancelleryInitialValues,
    resolver: zodResolver(chancellerySchema),
  })

  const { control, reset, watch, getValues, handleSubmit, setValue } = methods

  const onSubmit = (values: ChancelleryFormValues): Promise<SaveChancelleryMutation | void> => {
    const {
      active,
      callbacks,
      chancelleryId,
      contactPersonFamilyName,
      contactPersonGivenName,
      contactPersonSalutation,
      deleted,
      eConsultId = '',
      email,
      iban,
      invoiceEmail,
      invoiceNumberRange,
      letterheadAddress,
      letterheadCity,
      letterheadEmail,
      letterheadPhone,
      letterheadWeb,
      letterheadZip,
      mollieId,
      name,
      orderVolumes,
      phone,
      powerBI1 = '',
      powerBI2 = '',
      secupay,
      services,
      taxId,
      taxNumber,
      throttle,
    } = values
    const throttleNuber = parseInt(throttle.max, 10) || 0

    const input: ChancelleryInput = {
      name,
      active,
      deleted,
      contactPerson: {
        foa: contactPersonSalutation,
        firstname: contactPersonGivenName,
        lastname: contactPersonFamilyName,
      },
      matchingThrottleCount: throttle.apply && throttleNuber > 0 ? throttleNuber : null,
      phone,
      email: email.trim(),
      orderVolumes: orderVolumes
        .filter((entry): entry is LocalOrderVolume => Boolean(entry.fieldOfLaw?.id))
        .map(entry => ({
          weeklyMax: entry.weeklyMax ? parseInt(entry.weeklyMax, 10) : 0,
          fieldOfLawId: entry.fieldOfLaw.id,
        })),
      eConsult: {
        active: eConsultId.length > 0,
        customerId: eConsultId,
      },
      secupay: {
        active: secupay.length > 0,
        contractId: secupay,
      },
      letterhead: {
        address: letterheadAddress,
        city: letterheadCity,
        email: letterheadEmail?.trim(),
        phone: letterheadPhone,
        web: letterheadWeb,
        zip: letterheadZip,
      },
      mollieId,
      services,
      callbacks,
      iban,
      taxNumber,
      taxId,
      invoiceEmail: invoiceEmail.trim(),
      invoiceNumberRange,
    }
    const withPowerBI = addOptionalPropIf<{ powerBI: Array<string> }, ChancelleryInput>(
      powerBI1.length > 0 && powerBI2.length > 0
    )({
      powerBI: [powerBI1, powerBI2],
    })
    const withId = addOptionalPropIf<{ id: string }, ChancelleryInput>(!!chancelleryId && chancelleryId.length > 0)({
      id: chancelleryId ?? '',
    })

    const withOptionalValues = pipe(withId, withPowerBI)
    const wholeInput: ChancelleryInput = withOptionalValues(input)
    return saveChancellery(wholeInput)
      .then(({ saveChancellery: savedChancellery }) => {
        if (!savedChancellery) {
          return { saveChancellery: savedChancellery }
        }
        reset(mapChancelleryAPIDataToForm(savedChancellery))
        enqueueSnackbar(
          savedChancellery.deleted
            ? 'Die Kanzlei wurde erfolgreich als gelöscht markiert'
            : `Kanzlei "${savedChancellery.name}" wurde erfolgreich gespeichert.`,
          { variant: 'success' }
        )

        const to = !id ? TO_EDIT_VIEW.fillFromObject({ id: savedChancellery.id }) : undefined

        if (to) {
          redirect(to)
        }

        return { saveChancellery: savedChancellery }
      })
      .catch(() => {
        enqueueSnackbar(`Das abspeichern der Kanzlei war nicht erfolgreich.`, { variant: 'error' })
      })
  }

  const [deleted, chancelleryId, applyThrottle] = watch(['deleted', 'chancelleryId', 'throttle.apply'])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getChancelleryData(id).then(data => {
        reset(data)
        setMatchingConfigs(data?.matchingConfigs ?? [])
        setMatchings(data?.matchings ?? [])
        setIsLoading(false)
      })
    } else {
      reset()
    }
  }, [id, reset])

  useScrollToTop()

  const disabled = isLoading || deleted

  return (
    <PageLayout heading={deleted ? 'Kanzleidetails' : id ? 'Kanzlei bearbeiten' : 'Kanzlei anlegen'}>
      <FallbackIssueWarnings />
      <Box component="form" id="chancellery" onSubmit={handleSubmit(onSubmit, onError)}>
        <LoadingMessage isLoading={isLoading} />
        {deleted && (
          <Box mb={3}>
            <Alert severity="warning">Diese Kanzlei wurde als „ausgetreten“ markiert. Änderungen sind nicht mehr möglich.</Alert>
          </Box>
        )}
        <Grid container spacing={6}>
          <Grid
            sm={12}
            display="grid"
            gridTemplateColumns={{ sm: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }}
            gridAutoFlow="row dense"
            gap={3}
          >
            <Checkbox sx={{ gridColumn: '1 / 3' }} control={control} name="active" label="Aktiv" disabled={disabled} />
            <TextField control={control} name="name" label="Name Kanzlei" disabled={disabled} fullWidth />
            <TextField
              control={control}
              name="contactPersonSalutation"
              label="Ansprechpartner Anrede"
              placeholder='z.B. "Frau"'
              disabled={disabled}
              fullWidth
            />
            <TextField
              control={control}
              name="contactPersonFamilyName"
              label="Ansprechpartner Name"
              disabled={disabled}
              fullWidth
            />
            <TextField
              control={control}
              name="contactPersonGivenName"
              label="Ansprechpartner Vorname(n)"
              disabled={disabled}
              fullWidth
            />
            <TextField control={control} name="phone" label="Telefon" disabled={disabled} fullWidth />
            <TextField control={control} name="email" label="E-Mail-Adresse" disabled={disabled} fullWidth />
            {chancelleryId && <TextField control={control} name="chancelleryId" label="Kanzlei-ID" disabled fullWidth />}
            <TextField
              control={control}
              name="iban"
              label="IBAN"
              disabled={disabled}
              fullWidth
              infoText="Wird für Dunkelverarbeitungsstrecke bei der DEVK benötigt"
            />
            <TextField
              control={control}
              name="eConsultId"
              label="E-Consult ID"
              disabled={disabled}
              fullWidth
              infoText="Muss nur eingetragen werden, wenn Aktenübertragung über E-Consult-Schnittstelle eingerichtet wurde"
            />
            <TextField
              control={control}
              name="secupay"
              label="Secupay Nummer"
              disabled={disabled}
              fullWidth
              infoText="Muss nur eingetragen werden, wenn Kanzlei Produkte über B2B verkauft"
            />
            <TextField
              control={control}
              name="mollieId"
              label="Mollie ID"
              disabled={disabled}
              fullWidth
              infoText="Muss nur eingetragen werden, wenn Kanzlei Produkte über B2C verkauft"
            />
            <TextField
              sx={{ gridColumn: '1/3' }}
              control={control}
              name="services"
              label="Kunden Benefits"
              disabled={disabled}
              multiline
              rows={1}
              fullWidth
            />
            <Checkbox control={control} name="callbacks" label="Rückrufe aktiviert / Macht Rückrufe selbst" disabled={disabled} />
          </Grid>
          <Grid sm={12} component="fieldset">
            <FormProvider {...methods}>
              <OrderVolumeSetting disabled={disabled} />
            </FormProvider>
            <Box display="grid">
              <Box>
                <Checkbox control={control} name="throttle.apply" label="Volumen drosseln" />
              </Box>
              {applyThrottle && (
                <Typography display="flex" component="div" alignItems="center" gap={2}>
                  Maximal <TextField control={control} name="throttle.max" /> Matches pro Stunde
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            sm={12}
            component="fieldset"
            display="grid"
            gridTemplateColumns={{ sm: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }}
            gap={3}
          >
            <InputLegend>Rechnungsrelevante Informationen (Produktverkauf B2C)</InputLegend>
            <TextField
              control={control}
              name="invoiceEmail"
              label="E-Mail-Adresse für Rechnungsversand"
              disabled={disabled}
              fullWidth
            />
            <TextField control={control} name="invoiceNumberRange" label="Rechnungsnummernkreis" disabled={disabled} fullWidth />
          </Grid>
          <Grid
            sm={12}
            component="fieldset"
            display="grid"
            gridTemplateColumns={{ sm: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }}
            gap={3}
          >
            <InputLegend>Informationen Für Briefkopf (auf Rechnung)</InputLegend>
            <TextField control={control} name="letterheadPhone" label="Telefon" disabled={disabled} fullWidth />
            <TextField control={control} name="letterheadEmail" label="E-Mail" disabled={disabled} fullWidth />
            <TextField control={control} name="letterheadAddress" label="Adresse" disabled={disabled} fullWidth />
            <Box display="flex" gap={3} sx={{ p: '0' }}>
              <TextField
                control={control}
                name="letterheadZip"
                label="Postleitzahl"
                disabled={disabled}
                fullWidth
                sx={{ maxWidth: '50%' }}
              />
              <TextField
                control={control}
                name="letterheadCity"
                label="Stadt"
                disabled={disabled}
                fullWidth
                sx={{ maxWidth: '50%' }}
              />
            </Box>
            <TextField control={control} name="letterheadWeb" label="Webseite" disabled={disabled} fullWidth />
          </Grid>
          <Grid
            xs={12}
            component="fieldset"
            display="grid"
            gridTemplateColumns={{ sm: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }}
            gap={3}
          >
            <InputLegend>Steuerrechtliche Informationen</InputLegend>
            <TextField control={control} name="taxId" label="Umsatzsteuer ID" disabled={disabled} fullWidth />
            <TextField control={control} name="taxNumber" label="Steuernummer" disabled={disabled} fullWidth />
          </Grid>

          {chancelleryId ? (
            <Grid xs={12}>
              <ChancelleryLocationsTable chancelleryId={chancelleryId} disabled={disabled} />
              {matchingConfigs && <MatchingConfigsTable matchingConfigs={matchingConfigs} chancelleryId={chancelleryId} />}
              <GlobalHolidays title="Für alle Standorte geltende Urlaubszeiten" chancelleryId={chancelleryId} />
            </Grid>
          ) : (
            <Grid xs={12} display="flex" alignItems="center" mb={3}>
              <InfoIcon color="primary" sx={theme => ({ marginRight: theme.spacing(2) })} />
              <Typography>Um Standorte hinzuzufügen, müsssen Sie die Kanzlei erst mit „Speichern“ anlegen.</Typography>
            </Grid>
          )}

          <Grid xs={12}>
            <SimpleUserTable title="Verknüpfte Anwälte" users={getValues('users')} />
          </Grid>
          <Grid xs={12}>
            <MatchingsTable matchings={matchings} />
          </Grid>

          <Grid xs={12} component="fieldset">
            <InputLegend>Power BI</InputLegend>
            <Stack spacing={3}>
              <TextField control={control} name="powerBI1" label="Link für Bearbeitungsstand" disabled={disabled} fullWidth />
              <TextField control={control} name="powerBI2" label="Link für Umwandlungsquote" disabled={disabled} fullWidth />
            </Stack>
          </Grid>
        </Grid>
        <FormActionBox>
          <>
            {id && !deleted && (
              <DeleteWithConfirmation
                actionButtonText="Kooperation beenden"
                onConfirm={async () => {
                  setValue('deleted', true)
                  onSubmit(getValues()).then(() => redirect(TO_OVERVIEW))
                }}
              >
                <Typography>Diese Aktion ist nicht umkehrbar. Kooperation mit Kanzlei wird permanent beendet.</Typography>
              </DeleteWithConfirmation>
            )}
            <AbortButton
              onClick={() => {
                redirect(TO_OVERVIEW)
              }}
            >
              {deleted ? 'Schließen' : null}
            </AbortButton>
            {deleted ? null : (
              <>
                <SaveButton form="chancellery" />
                <SaveButton
                  submit={false}
                  onClick={handleSubmit(values => {
                    onSubmit(values).then(() => redirect('/chancelleries'))
                  }, onError)}
                >
                  Speichern und Schließen
                </SaveButton>
              </>
            )}
          </>
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
