import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { GridColDef, GridCellParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { FILE_BASE } from 'app/constants'
import {
  FilterOperator,
  PageLayout,
  HeaderButtonGroup,
  PageInfoline,
  createGridColumnsFrom,
  CenterBox,
  ExportButton,
  Badge,
  useGridData,
  MuiDataGrid,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { useScrollToTop } from 'common/hooks'
import { formatDateTime, request } from 'common/utils'

import { FallbackIssueWarnings } from '../components'
import getCSVExportQuery from '../graphql/getCSVExport.graphql'
import chancelleriesQuery from '../graphql/getChancelleries.graphql'
import {
  ChancelleryStatus,
  GetChancelleriesQuery,
  GetCsvExportQuery,
  GetCsvExportQueryVariables,
} from '../interfaces/schemaDefinition'

const getExportUrl = (): Promise<string> =>
  request<GetCsvExportQuery, GetCsvExportQueryVariables>(CHANCELLERIES_ENDPOINT, getCSVExportQuery).then(
    result => `${FILE_BASE}/${result.createExport}`
  )

const getStatus: FC<GridCellParams> = params => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const row = params.row as GetChancelleriesQuery['chancelleries']['list'][number]
  const inheritInactive = row.chancelleryLocations.every(location => !location.active)
  const status = inheritInactive ? ChancelleryStatus.Inactive : row.status
  return status === ChancelleryStatus.Archived ? (
    <CenterBox>
      <Badge color="error">Ausgetreten</Badge>
    </CenterBox>
  ) : status === ChancelleryStatus.Inactive ? (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  ) : status === ChancelleryStatus.Limited ? (
    <CenterBox>
      <Badge color="warning" title="Ein oder mehrere Standorte sind nicht oder nur eingeschränkt verfügbar.">
        Eingeschränkt
      </Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const columns: Array<GridColDef> = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  { field: 'name', headerName: 'Kanzlei', flex: 0.25, minWidth: 350 },
  {
    field: 'modifiedAt',
    headerName: 'Geändert am',
    valueFormatter: params => dayjs(params.value as string).format('DD.MM.YYYY'),
    width: 200,
  },
  { field: 'status', sortable: false, filterable: false, headerName: 'Status', width: 150, renderCell: getStatus },
  {
    field: 'lastMatched',
    filterable: false,
    headerName: 'Letzte Steuerung',
    width: 150,
    valueGetter: params => params.value?.date,
    renderCell: params => {
      const { value, row } = params
      const lastMatched = value ? formatDateTime(new Date(value)) : '-'
      return (
        <CenterBox>
          <Typography variant="body2" color={row.lastMatched.notReached ? 'red' : 'default'}>
            {lastMatched}
          </Typography>
        </CenterBox>
      )
    },
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const ChancelleryListPage: FC = () => {
  const history = useHistory()

  const { data, error, actions, tableState } = useGridData<'chancelleries', GetChancelleriesQuery>(
    CHANCELLERIES_ENDPOINT,
    chancelleriesQuery,
    'chancelleries',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'tags',
          operator: FilterOperator.Empty,
          value: '',
        },
        {
          name: 'deleted',
          operator: FilterOperator.Equals,
          value: false,
        },
      ],
      pageSize: 100,
    }
  )

  const existingFilters = tableState.filters ?? []

  const hasDeleted = !existingFilters.find(({ name }) => name === 'deleted')

  const toggleFilter = (): void => {
    actions.setAPIFilter(
      !hasDeleted
        ? existingFilters.filter(({ name }) => name !== 'deleted')
        : existingFilters.concat([
            {
              name: 'deleted',
              operator: FilterOperator.Equals,
              value: false,
            },
          ])
    )
  }
  useScrollToTop()

  const totalCount = data?.total ?? 0
  const deletedCount = data?.totalDeleted ?? 0
  const inactiveCount = data?.totalInactive ?? 0
  const limitedCount = data?.totalLimited ?? 0
  const activeCount = totalCount - deletedCount - inactiveCount

  return (
    <PageLayout error={error} heading="Kanzleiverwaltung" spacing="table">
      <FallbackIssueWarnings />
      <HeaderButtonGroup>
        <ExportButton
          getExportUrl={getExportUrl}
          hoverText="Liste aller Kanzleien als CSV-Datei herunterladen"
          dialogTitle="CSV-Export"
        />
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/chancelleries/new')}>
          <AddCircleOutlineIcon titleAccess="Kanzlei hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>
        Insgesamt {totalCount} Einträge | {activeCount} aktiv | {inactiveCount} inaktiv | {limitedCount} eingeschränkt |{' '}
        {deletedCount} ausgetreten
      </PageInfoline>
      <Box mx={2} my={1}>
        <FormControlLabel
          control={<Checkbox checked={hasDeleted} onChange={toggleFilter} />}
          label="Ausgetretene Kanzleien anzeigen"
        />
      </Box>
      <MuiDataGrid
        actions={actions}
        tableState={tableState}
        autoHeight
        initialState={{
          pagination: {
            pageSize: 100,
          },
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        columns={columns}
        rows={data?.list ?? []}
        /* eslint-disable-next-line fp/no-mutating-methods */
        onRowClick={r => (r.row.id ? history.push(`/chancelleries/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
