import { Box, Link } from '@mui/material'
import { GridColumns, DataGrid } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { createGridColumnsFrom } from 'common/components-mui'
import { formatDateTime } from 'common/utils'
import { getTableHeight } from 'packages/chancellery-search/components/AdviceRequestHistoryTable/AdviceRequestHistoryTable'

import { GetChancelleryQuery } from '../interfaces/schemaDefinition'

import { InputLegend } from './InputLegend'

const formatDateCell = ({ value }: { value?: string }): string => (value ? formatDateTime(new Date(value)) : '-')

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  {
    field: 'adviceId',
    filterable: false,
    sortable: false,
    headerName: 'Antrag',
    renderCell: ({ value }) => (
      <Link underline="always" component={NavLink} to={`/advice-requests/form?adviceId=${value}`}>
        {value}
      </Link>
    ),
    flex: 0.167,
    minWidth: 150,
  },
  {
    field: 'fieldOfLaw',
    sortable: false,
    headerName: 'Rechtsgebiet',
    flex: 0.167,
    minWidth: 150,
  },
  {
    field: 'matchedAt',
    sortable: false,
    headerName: 'Gesteuert am',
    valueFormatter: formatDateCell,
    flex: 0.167,
    minWidth: 150,
  },
  {
    field: 'canceledAt',
    sortable: false,
    headerName: 'Abgebrochen am',
    valueFormatter: formatDateCell,
    flex: 0.167,
    minWidth: 150,
  },
  {
    field: 'cancelReason',
    sortable: false,
    headerName: 'Abbruchgrund',
    width: 150,
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const MatchingsTable: FC<{
  matchings: GetChancelleryQuery['chancellery']['matchings']
}> = ({ matchings }) => {
  const tableHeight = getTableHeight(4)
  return (
    <Box>
      <InputLegend>Steuerungen</InputLegend>
      <Box component="fieldset" sx={{ mt: 3, height: `${tableHeight}px` }}>
        <DataGrid columns={columns} rows={matchings ?? []} rowCount={matchings?.length ?? 0} />
      </Box>
    </Box>
  )
}
