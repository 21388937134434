import { Box } from '@mui/material'
import { GridColumns, DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Badge, CenterBox, createGridColumnsFrom } from 'common/components-mui'

import { SimpleUserTableProps } from '../interfaces'

import { InputLegend } from './InputLegend'

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  { field: 'name', headerName: 'Name', flex: 0.33, minWidth: 150 },
  {
    field: 'email',
    headerName: 'E-Mail',
    flex: 0.33,
    minWidth: 150,
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 0.1,
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      const active: boolean = params.value as boolean
      return (
        <CenterBox>
          <Badge color={active ? 'success' : 'default'}>{active ? 'Aktiv' : 'Inaktiv'}</Badge>
        </CenterBox>
      )
    },
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const SimpleUserTable: FC<SimpleUserTableProps> = ({ users, title }) => {
  const history = useHistory()

  return (
    <Box component="fieldset">
      <InputLegend>{title}</InputLegend>

      <DataGrid
        sx={{ marginTop: 2 }}
        autoHeight
        columns={columns}
        rows={users}
        pageSize={50}
        rowCount={users.length ?? 0}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/users/edit/${r.row.id}`) : undefined)}
      />
    </Box>
  )
}
