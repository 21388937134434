import * as z from 'zod'

import { FIELDS_OF_LAW } from 'common/constants'

import { Contact, PaymentMethod, FeatureEnum } from '../interfaces'
import { calculatePrice, convertToNum, isKebabCase } from '../utils'

const folSchema = z.object({
  id: z.string().refine(value => Object.keys(FIELDS_OF_LAW).includes(value), {
    message: 'Dies ist kein bekanntes Rechtsgebiet',
    path: ['fieldsOfLaw'],
  }),
  name: z.string(),
})

const partnerSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export const featureSchema = z.object({
  id: z.string(),
  name: z.string().trim().min(1, 'Bitte geben Sie einen Namen für die Leistung an'),
  type: z.enum([FeatureEnum.Excluded, FeatureEnum.Normal, FeatureEnum.Optional, FeatureEnum.Special], {
    required_error: 'Bitte wählen Sie eine Leisungsart aus',
    invalid_type_error: 'Bitte wählen Sie eine Leisungsart aus',
  }),
  info: z.string().optional(),
})

export const partnerDiscountSchema = z.object({
  id: z.string(),
  partners: z.array(partnerSchema).min(1, 'Mindestens ein Partner ist erforderlich'),
  discount: z.string(),
  disNetPrice: z.string(),
  disGrossPrice: z.string(),
  active: z.boolean(),
})

export const productSchema = z
  .object({
    id: z
      .string()
      .trim()
      .min(1, 'ID des Produkts ist erforderlich')
      .refine(isKebabCase, { message: 'Bitte geben Sie eine gültige ID an (kebab-case)', path: ['id'] }),
    active: z.boolean(),
    locked: z.boolean(),
    name: z.string({ required_error: 'Name des Produkts ist erforderlich' }).trim().min(1, 'Name des Produkts ist erforderlich'),
    title: z
      .string({ required_error: 'Titel des Produkts ist erforderlich' })
      .trim()
      .min(1, 'Titel des Produkts ist erforderlich'),
    subtitle: z
      .string({ required_error: 'Untertitel des Produkts ist erforderlich' })
      .trim()
      .min(1, 'Untertitel des Produkts ist erforderlich'),
    netPrice: z
      .string({ required_error: 'Preis des Produkts ist erforderlich' })
      .trim()
      .min(1, 'Preis des Produkts ist erforderlich')
      .regex(/^(\d*)([,])?(\d{1,2})$/, 'Bitte geben Sie einen gültigen Preis im richtigen Format ein, z.B. 49,99 oder 50'),
    rrp: z
      .string()
      .optional()
      .refine(value => (value ? /^(\d*)([,])?(\d{1,2})$/.test(value) : true), {
        message: 'Bitte geben Sie einen gültigen Preis im richtigen Format ein, z.B. 49,99 oder 50',
        path: ['rrp'],
      }),
    contactTypes: z.array(z.enum([Contact.Callback, Contact.Email, Contact.Redirect] as const)).min(1),
    fieldsOfLaw: z.array(folSchema).min(1, 'Mindestens ein Rechtsgebiet ist erforderlich'),
    partners: z.array(partnerSchema).min(1, 'Mindestens ein Partner ist erforderlich'),
    matching: z.object({
      ignoreOrderVolume: z.boolean(),
    }),
    marketing: z.object({
      awinSaleType: z.string(),
    }),
    payment: z.object({
      methods: z.array(z.enum([PaymentMethod.Creditcard, PaymentMethod.Klarna, PaymentMethod.Paypal])),
      chancelleryId: z.string(),
    }),
    features: z.array(featureSchema),
    // We use object instead of string to utilise React-Hook-Forms FieldArray-Methods
    services: z.array(z.object({ service: z.string() })),
    partnerDiscounts: z.array(partnerDiscountSchema),
  })
  .refine(
    ({ rrp, netPrice }) => {
      if (!rrp) return true

      const rrpAsNumber = convertToNum(rrp)
      const netPriceNumber = convertToNum(calculatePrice(netPrice))

      return rrpAsNumber >= netPriceNumber
    },
    {
      message: 'Der UVP darf nicht unter dem Bruttopreis liegen.',
      path: ['rrp'],
    }
  )
  .refine(
    ({ payment: { methods }, netPrice }) => {
      const noNetPrice = !netPrice || convertToNum(netPrice) === 0
      const hasPaymentMethod = methods.length > 0

      return noNetPrice || hasPaymentMethod
    },
    {
      message: 'Solange ein Preis angegeben ist, ist mindestens eine Zahlungsart erfordelich.',
      path: ['payment.methods'],
    }
  )
