import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import {
  createGridColumnsFrom,
  HeaderButtonGroup,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
  CenterBox,
  Badge,
} from 'common/components-mui'
import { PARTNERS_ENDPOINT } from 'common/constants'

import partnersQuery from '../graphql/getPartners.graphql'
import { GetPartnersQuery } from '../interfaces/schemaDefinition'

const getStatus = (params: GridCellParams): React.ReactElement =>
  params.row.active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )

const columns: Array<GridColDef> = createGridColumnsFrom([
  { field: 'name', headerName: 'Partner', flex: 0.25, minWidth: 200 },
  { field: 'active', sortable: false, filterable: false, headerName: 'Status', width: 150, renderCell: getStatus },
])

export const PartnersListPage: FunctionComponent = () => {
  const { data, error, actions, tableState } = useGridData<'partners', GetPartnersQuery>(
    PARTNERS_ENDPOINT,
    partnersQuery,
    'partners',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      pageSize: 100,
    }
  )
  const history = useHistory()

  const totalCount = data?.total ?? 0

  return (
    <PageLayout error={error} heading="Partnerverwaltung" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/partners/new')}>
          <AddCircleOutlineIcon titleAccess="Kanzlei hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {totalCount} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        loading={!data}
        rowCount={totalCount}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/partners/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
