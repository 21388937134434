import { formatCurrency } from '@grapes-agency/universal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { Button } from '@mui/material'
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { MuiDataGrid, useGridData, PageLayout, HeaderButtonGroup, PageInfoline, CenterBox, Badge } from 'common/components-mui'
import { PRODUCTS_BUSINESS_ENDPOINT } from 'common/constants/endpoints'

import getProductsBusinessQuery from '../graphql/getProductsBusiness.graphql'
import { GetProductsQuery } from '../interfaces/schemaDefinition'

interface ListProduct {
  name: string
  price: string
  labelPrice: string
  active: React.ReactElement
  type: string
  createdBy: { name: string }
  fieldsOfLaw: string
  variants: Array<{ id: string }>
  actions: React.ReactElement
}

const dataObjectName = 'products'

const getStatus = (params: GridCellParams): React.ReactElement => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const active = params.row.active as Boolean
  return active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const columns: Array<GridColDef> = [
  /* eslint-disable @typescript-eslint/consistent-type-assertions, react/display-name */
  { field: 'name', headerName: 'Produktname', flex: 0.2, minWidth: 250 },
  {
    field: 'price',
    headerName: 'Preis',
    flex: 0.1,
    minWidth: 100,
    valueFormatter: (params: GridValueFormatterParams) => formatCurrency({ unit: 'EUR' })(params.value as number),
  },
  {
    field: 'labelPrice',
    headerName: 'Fake-Preis',
    flex: 0.1,
    minWidth: 100,
    valueFormatter: (params: GridValueFormatterParams) => formatCurrency({ unit: 'EUR' })(params.value as number),
  },
  {
    field: 'createdBy',
    headerName: 'Erstellt durch',
    flex: 0.2,
    minWidth: 150,
    valueFormatter: (params: GridValueFormatterParams) => (params.value as ListProduct).name,
  },
  {
    field: 'fieldsOfLaw',
    headerName: 'Rechtsgebiete',
    flex: 0.2,
    minWidth: 150,
  },
  {
    field: 'variants',
    headerName: 'Varianten',
    flex: 0.1,
    minWidth: 100,
    valueFormatter: (params: GridValueFormatterParams) => (params.value as Array<{ id: string }>).length || 0,
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 0.1,
    minWidth: 100,
    renderCell: getStatus,
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions, react/display-name */
]

export const ProductsBusinessListPage: FC = () => {
  const history = useHistory()

  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetProductsQuery>(
    PRODUCTS_BUSINESS_ENDPOINT,
    getProductsBusinessQuery,
    dataObjectName
  )

  return (
    <PageLayout error={error} heading="Produktverwaltung Business" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/product-business/new')}>
          <AddCircleOutlineIcon titleAccess="Produkt hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {data?.total ?? 0} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        loading={!data}
        rows={data?.list ?? []}
        columns={columns}
        rowCount={data?.total}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/product-business/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
