import { formatCurrency } from '@grapes-agency/universal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useHistory } from 'react-router'

import {
  createGridColumnsFrom,
  FilterOperator,
  HeaderButtonGroup,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
} from 'common/components-mui'
import { PRODUCTS_ENDPOINT } from 'common/constants'

import { getStatus } from '../components'
import getProducts from '../graphql/getProducts.graphql'
import { GetProductsQuery } from '../interfaces/schemaDefinition'

const dataObjectName = 'products'

const columns: Array<GridColDef> = createGridColumnsFrom([
  { field: 'name', headerName: 'Produktname', flex: 0.25, minWidth: 200 },
  {
    field: 'netPrice',
    headerName: 'Nettopreis',
    flex: 0.1,
    minWidth: 100,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    valueFormatter: (params: GridValueFormatterParams) => formatCurrency({ unit: 'EUR' })(params.value as number),
  },
  {
    field: 'modifiedAt',
    headerName: 'Geändert am',
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('DD.MM.YYYY'),
    width: 150,
  },
  { field: 'active', headerName: 'Status', renderCell: getStatus, minWidth: 100 },
])

export const ProductsListPage: FC = () => {
  const history = useHistory()
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetProductsQuery>(
    PRODUCTS_ENDPOINT,
    getProducts,
    dataObjectName,
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'tags',
          operator: FilterOperator.Empty,
          value: '',
        },
      ],
    }
  )

  return (
    <PageLayout error={error} heading="Produktverwaltung Consumer" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/products/new')}>
          <AddCircleOutlineIcon titleAccess="Produkt hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>
        Insgesamt {data?.total ?? 0} {data && data.total > 1 ? 'Einträge' : 'Eintrag'}
      </PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={data?.list ?? []}
        loading={!data}
        rowCount={data?.total ?? 0}
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={r => (r.row.id ? history.push(`/products/edit/${r.row.id}`) : undefined)}
      />
    </PageLayout>
  )
}
