import * as z from 'zod'

export const adviceForeignFormSchema = z.object({
  damageNumber: z
    .string({
      required_error: 'Schadensnummer ist erforderlich',
    })
    .length(14, '14-stellige Schadensnummer ist erforderlich'),
  country: z
    .string({
      required_error: 'Land ist erforderlich',
    })
    .trim()
    .min(1, 'Land ist erforderlich')
    .nullable(),
  fieldOfLaw: z
    .string({ required_error: 'Ereignisart ist erforderlich' })
    .trim()
    .min(1, 'Ereignisart ist erforderlich')
    .nullable(),
  description: z.string({ required_error: 'Ursache ist erforderlich' }).trim().min(1, 'Ursache ist erforderlich'),
})
